<template>
    <div>
        <highcharts v-if="loaded" :options="chartOptions" ref="chart"/>
        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import Highcharts from 'highcharts'
    import exportingInit from "highcharts/modules/exporting"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    exportingInit(Highcharts)

    export default {
        name: 'CompaniesByTrackersAndCategories',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartName: "StartupsByTrackersAndCategories",
                chartData: [],
                chartOptions: {
                    chart: {
                        type: 'column',
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Companies by Trackers and Categories',
                        align: 'left'
                    },
                    plotOptions: {
                        column: {
                            stacking: "normal",
                        },
                        series: {
                            "borderWidth": 0,
                            "dataLabels": {
                                "enabled": true,
                                "style": {
                                    "textShadow": false,
                                    "fontSize": "10px"
                                }
                            }
                        }
                    },
                    xAxis: {
                        categories: []
                    },
                    yAxis: {
                        type: "logarithmic",
                        title: {
                            text: 'Companies count'
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                color: ( // theme
                                    Highcharts.defaultOptions.title.style &&
                                    Highcharts.defaultOptions.title.style.color
                                ) || 'gray',
                                textOutline: 'none'
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: '<b>{point.x}</b><br/>',
                        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                    },
                    series: []
                }
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('StartupsByTrackersAndCategories').then(response => {
                    this.chartData = response.data
                    this.formatAndSetChartData()

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },
            formatAndSetChartData() {
                this.chartOptions.xAxis.categories = this.chartData.categories
                this.chartOptions.series = this.chartData.series.map(item => ({
                    ...item,
                    events: {
                        click: (e) => {
                            this.openStartupsModal(e.point.category, item.name)
                        }
                    }
                }))
            },
            openStartupsModal(tracker, category) {
                this.$emit('showStartups', [this.chartName, `Startups in tracker "${tracker}" of "${category}" category`, JSON.stringify([tracker, category])])
            }
        }
    }
</script>
