import dashboardService from "@/services/dashboard.service";

const dashboardChartMixin = {
    props: {
        chartSettings: {
            type: Object,
            required: true,
        },

        filters: Object,
    },

    mounted() {
        this.fetchChartData(true)
    },

    watch: {
        chartSettings: {
            handler() {
                this.reflowChart()
            },

            deep: true
        },

        filters() {
            this.fetchChartData()
        },
    },

    methods: {
        reflowChart() {
            if (this.$refs.chart) {
                this.$refs.chart.chart.reflow()
            }
        },

        fetchChartData() {
            console.log("That's method from mixin. Please implement one regarding your component needs.")
        },

        getChartData(chartType) {
            return dashboardService.getChartData(chartType, this.filters)
        },
    }
}

export default dashboardChartMixin
